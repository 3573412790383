<template>
  <div class="connectus">
    <div class="connect_img"></div>
    <div class="connect_title">
      我们为您提供全心全意满意的服务咨询，希望您 能够支持和监督我们的服务。
    </div>
    <div class="connect_time">工作时间：09:00 — 22:00</div>
    <div class="connect_btn"  @click="callPhone">联系我们</div>
  </div>
</template>
<script>
export default {
  methods:{
    callPhone() {
      window.location.href = "tel://4008-515-258";
    },
  }
}
</script>
<style lang="scss" scoped>
.connectus {
  width: 100%;
  height: 100%;
  background: #f8f8f8;
  overflow-y: auto;
  .connect_img {
    margin: 0 auto;
    width: 375px;
    height: 180px;
    background:url('../assets/img/lxwm.png') no-repeat;
    background-size: cover;
  }
  .connect_title {
    width: 375px;
    padding: 16px 19px 10px 20px;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    line-height: 22px;
  }
  .connect_time {
    width: 375px;
    padding: 0px 19px 0px 20px;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    line-height: 22px;
  }
  .connect_btn {
    margin: 0 auto;
    width: 335px;
    height: 40px;
    background: linear-gradient(135deg, #35c3ff 0%, #1890ff 100%);
    border-radius: 20px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    margin-top: 107px;
    box-shadow: 0 0 0 gray, 0px 0px 0px gray, 0 2px 1px #35c3ff,
      0px 0px 0px gray;
  }
}
</style>